<!--
 * @Author: Neko
 * @Date: 2021-01-11 09:18:17
 * @LastEditTime: 2021-04-21 10:28:18
 * @LastEditors: Neko
-->
<template>
  <div class="address__container">
    <div class="address__container-wrap">

      <div class="address__container-header">
        <div class="header">
          <div class="header__wrap">
            <h2 class="header__title">
              <span class="title">{{ $t('address.addres') }}</span>
            </h2>
          </div>
        </div>
      </div>

      <div class="address__wrap">
        <!-- <Breadcrumbs
          :data="breadcrumbs"
          name-label="name"
          value-label="id"
          @click="onClickBreadcrumbs"
        /> -->
        <div class="address__main">
          <div class="left">
            <AddressForm ref="addressForm" type="column" @confirm="onConfirmSaveAddressAction" />
            <!-- <el-form
              ref="addForm"
              class="address-form"
              :inline="false"
              label-position="top"
              :model="addressForm"
              :rules="ruler"
            >
              <el-form-item label="收货人：" prop="consignee">
                <el-input v-model="addressForm.consignee" placeholder="请输入" />
              </el-form-item>

              <el-form-item label="手机号码：" prop="mobile">
                <el-input v-model="addressForm.mobile" placeholder="请输入" />
              </el-form-item>

              <el-form-item label="省/市/区：" prop="prov">
                <address-select :citys="citys" @change="onAddressChange" />
              </el-form-item>

              <el-form-item label="详细地址：" prop="address">
                <el-input v-model="addressForm.address" type="textarea" placeholder="请输入" />
              </el-form-item>

              <el-form-item class="buttons">
                <el-button class="button" @click="onAddAddress">新增收货地址</el-button>
              </el-form-item>
            </el-form> -->
          </div>

          <div class="right">
            <!-- <div class="header">
              <h2 class="title">收货地址</h2>
            </div> -->

            <ul class="address__list">
              <li v-for="item in address" :key="item.id" :class="['item', item.defaultType === 1?'default':'' ]">
                <div v-if="item.defaultType === 1" class="address__default-badge">
                  {{ $t('address.defaultAddre') }}
                </div>

                <div class="address__line-1">
                  <span class="name">{{ item.consignee }}</span>
                  <span class="name">{{ item.postcode }}</span>
                  <span class="tel">{{ item.mobile }}</span>
                </div>

                <div class="address__line-2">
                  <!-- <dl class="address__info">
                    <dt>省/市/区：</dt>
                    <dd>{{ item.provName }} {{ item.cityName }} {{ item.areaName }}</dd>
                  </dl> -->

                  <dl class="address__info">
                    <dt>{{ item.countryName }}</dt>
                    <dd>{{ item.region }} {{ item.address }}</dd>
                  </dl>
                </div>

                <div class="buttons">
                  <a v-if="item.defaultType !== 1" href="javascript:void(0)" @click="onSetDefault(item.id)">{{ $t('address.setDefault') }}</a>
                  <a href="javascript:void(0)" @click="onEdit(item.id)">{{ $t('address.edit') }}</a>
                  <a href="javascript:void(0)" @click="onDelete(item.id)">{{ $t('address.delAddre') }}</a>
                </div>

                <!-- <div v-if="item.defaultType === 1" class="check-icon">
                  <i class="icon el-icon-check" />
                </div> -->
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <address-dialog :id="addressId" :visible.sync="visible" :is-edit="isEdit" @success="onSuccess" />
  </div>
</template>

<script>
// import Breadcrumbs from '@/components/Layout/Breadcrumbs.vue'
import useChangeBackground from '@/composition/useChangeBackground.js'
import { computed, reactive, ref, toRefs } from '@vue/composition-api'
import { useRequest } from '@/utils/request.js'
import { MessageBox, Message } from 'element-ui'
// import AddressSelect from '@/components/Address/AddressSelect'
import AddressDialog from '@/components/Address/AddressDialog.vue'
import AddressForm from '@/components/Address/AddressForm.vue'
import { validateMobile } from '@/utils'

export default {
  name: 'AddressList',

  components: {
    // Breadcrumbs,
    // AddressSelect,
    AddressForm,
    AddressDialog
  },

  setup(_, { root }) {
    const $t = root.$jst
    useChangeBackground('#f5f5f5')

    const { $router } = root

    const editForm = ref(null)
    const addForm = ref(null)
    const checkForm = ref(null)
    const addressForm = ref(null)

    const state = reactive({
      isEdit: false,
      isShowDialog: false,
      address: [],
      addressId: 0,
      visible: false,
      addressForm: {
        consignee: '',
        mobile: '',
        address: ''
      },
      citys: [],

      ruler: {
        consignee: { required: true, message: $t('address.input'), trigger: 'blur' },
        mobile: [{ required: true, message: $t('address.input'), trigger: 'blur' }, { validator: validateMobile, trigger: 'blur' }],
        address: { required: true, message: $t('address.input'), trigger: 'blur' },
        prov: { required: true, message: $t('address.choice'), trigger: 'blur' }
      },
      breadcrumbs: [
        { name: $t('address.myUser'), id: 'all' },
        { name: $t('address.myAddres'), id: '' }]
    })

    const onCloseDialog = _ => {
      state.editAddressForm = {}
      state.citys = []
      state.isShowDialog = false
      state.isEdit = false
    }

    const { fetch: getAddressList } = useRequest('address/addressList', {
      data: {
        pageNo: 1,
        pageSize: 10
      },
      isCustom: true,
      onSuccess: (res) => {
        const { data } = res
        state.address = data.records
      }
    })

    const { fetch: setDefault } = useRequest('address/setDefault', {
      data: computed(() => ({
        id: state.addressId
      })),
      isCustom: true,
      immediate: false,
      onSuccess: (res) => {
        const { code } = res
        if (code === 200) {
          getAddressList()
        }
      }
    })

    const { fetch: addressDelete } = useRequest('address/addressDelete', {
      data: computed(() => ({
        id: state.addressId
      })),
      isCustom: true,
      immediate: false,
      onSuccess: (res) => {
        getAddressList()
      }
    })

    const { fetch: addressAdd } = useRequest('address/addressAdd', {
      data: computed(() => ({
        ...state.addressForm,
        ...state.citys
      })),
      isCustom: true,
      immediate: false,
      onSuccess: (res) => {
        Message({
          message: $t('address.addAdsSuccess'),
          type: 'success'
        })
        state.addressForm = {}
        state.citys = []
        getAddressList()
      }
    })

    const onSetDefault = (id) => {
      state.addressId = id
      setDefault()
    }

    const onDelete = (id) => {
      if (state.address.length === 1) {
        Message({
          message: $t('address.oneAddres'),
          type: 'warning'
        })
        return
      }

      MessageBox.confirm($t('address.enterDel'), $t('address.delAdder'), {
        confirmButtonText: $t('address.confirm'),
        cancelButtonText: $t('address.cancel')
      }).then(() => {
        state.addressId = id
        addressDelete()
      }).catch(() => {
      })
    }

    const onEdit = (id) => {
      // state.visible = true
      // state.isEdit = true
      // state.addressId = id
      addressForm.value.setForm(id)
    }

    const onSuccess = () => {
      getAddressList()
    }

    const onAddressChange = (val) => {
      // console.log(val)
      state.addressForm = { ...state.addressForm, ...val }
    }

    const onAddAddress = () => {
      if (!addForm.value) return
      addForm.value.validate(isValid => {
        if (isValid) {
          addressAdd()
        }
      })
    }

    const onClickBreadcrumbs = (id) => {
      if (id.length === 0) {
        $router.push({ path: '/profile' })
      }
    }

    const onConfirmSaveAddressAction = _ => {
      getAddressList()
    }

    return {
      ...toRefs(state),
      editForm,
      addForm,
      checkForm,
      addressForm,

      onCloseDialog,
      onSetDefault,
      onDelete,
      onEdit,
      onAddressChange,
      onAddAddress,
      onSuccess,
      onClickBreadcrumbs,
      onConfirmSaveAddressAction
    }
  }
}
</script>

<style lang="scss" scoped>
.address__container {
  padding: 25px 0 111px;

  .address__container-wrap {
    min-width: 1200px;
    width: 96.9%;
    max-width: 1600px;
    margin: 0 auto;
    box-shadow: 0px 17px 102px 15px rgba(232, 232, 234, 0.95);

    .address__container-header {
      .header {
        position: relative;
        z-index: 2;
        background: #fff;

        .header__wrap {
          position: relative;
          z-index: 2;
          background: #fff;
        }

        .header__title {
          width: 1200px;
          margin: 0 auto;
          letter-spacing: 2px;
          font-size: 18px;

          .title {
            display: inline-block;
            padding: 33px 0 26px;
            border-bottom: 2px solid #0049AC;
            color: #0D5CAB;
          }

        }

      }
    }
  }

  .address__wrap {
    width: 1200px;
    margin: 0 auto;

    .breadcrumbs {
      margin-bottom: 20px;
    }
  }
}

.address__main {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;

  .left {
    width: 480px;
    padding: 26px 24px;
    border-radius: 6px;
    background: #fff;
  }

  .right {
    width: 680px;
    padding: 20px 39px 30px 40px;
    border-radius: 6px;
    background: #fff;

    .header {
      padding: 0 11px;
      border-left: 2px solid #0049AC;
      margin-bottom: 17px;

      .title {
        line-height: 22px;
        font-size: 16px;
        color: #333333;
      }
    }
  }
}

.address-form {
  .buttons {
    text-align: center;
    margin-top: 25px;

    .button {
      width: 206px;
      padding: 0;
      border-color: inherit;
      line-height: 36px;
      font-weight: normal;
      background: #4981E8;
      color: #fff;
    }
  }

  ::v-deep .el-form-item__label {
    line-height: 24px;
    color: #666;
  }

  ::v-deep .el-select {
    width: 100%;
  }

  ::v-deep .el-input__inner, ::v-deep .el-textarea__inner {
    border-radius: 0;
  }

  &.edit-form {
    ::v-deep .el-form-item__label {
      line-height: 40px;
      color: #666;
    }

    .buttons {
      text-align: left;

      .button {
        width: 99px;

        &.white {
          background: #fff;
          color: #4981E8;
        }
      }
    }
  }
}

.address__list {
  .item {
    position: relative;
    padding: 30px 0 31px;
    // border: 1px solid #E6E6E6;
    border-bottom: 1px solid #F2F2F2;
    margin-bottom: 26px;
    // background: #F7F7F7;

    &:hover {
      // background: #fff;
    }

    .address__default-badge {
      position: absolute;
      top: 18px;
      right: 26px;
      padding: 0 10px;
      // width: 80px;
      line-height: 30px;
      text-align: center;

      font-size: 14px;
      border: 1px solid #FF2900;
      border-radius: 25px;
      background: #FFEDE9;
      color: #FF2900;
    }

    .check-icon {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 40px;
      height: 40px;
      padding: 15px 3px 0 0;
      text-align: right;
      background: linear-gradient(135deg, transparent 0, transparent 50%, #4981E8 50%);

      .icon {
        font-size: 18px;
        color: #fff;
      }
    }

    &.default {
      // border-color: #0049AC;
    }

    .address__line-1 {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      margin-bottom: 10px;

      .name {
        display: inline-block;
        margin-right: 11px;
        font-size: 16px;
        color: #212121;
      }

      .tel {
        font-size: 14px;
        color: #ABABAB;
      }
    }

    .address__line-2 {
      margin-top: 20px;

      .address__info {
        font-size: 14px;
        // line-height: 30px;
        color: #666666;

        dd, dt {
          display: inline-block;
          margin: 0;
        }

        dt {
          padding-right: 10px;
          border-right: 1px solid #E6E6E6;
        }

        dd {
          padding-left: 10px;
        }
      }
    }

    .buttons {
      position: absolute;
      bottom: 5px;
      right: 24px;

      a {
        display: inline-block;
        font-size: 14px;
        line-height: 22px;
        color: #4981E8;

        & + a {
          margin-left: 16px;
        }
      }
    }
  }
}
</style>
